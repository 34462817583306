export const MetaData = {
    "/": {
        keywords: "wisecode, salesforce, salesforce partners, salesforce implementation partners, sfmc, marketing cloud, mulesoft, pardot",
        title: "Welcome | Wise Code",
        description: "Wise code in a software development firm focused on offering solution for marketing using salesforce marketing cloud"
    },
    "/#welcome": {
        keywords: "wisecode, salesforce, salesforce partners, salesforce implementation partners, sfmc, marketing cloud, mulesoft, pardot",
        title: "Welcome | Wise Code",
        description: "Wise code in a software development firm focused on offering solution for marketing using salesforce marketing cloud"
    },
    "/#marketing-cloud": {
        keywords: "wisecode, salesforce, salesforce partners, salesforce implementation partners, sfmc, marketing cloud, mulesoft, pardot",
        title: "Marketing Cloud | Wise Code",
        description: "Wise code in a software development firm focused on offering solution for marketing using salesforce marketing cloud"
    },
    "/#services": {
        keywords: "wisecode, salesforce, salesforce partners, salesforce implementation partners, sfmc, marketing cloud, mulesoft, pardot",
        title: "Services | Wise Code",
        description: "Wise code in a software development firm focused on offering solution for marketing using salesforce marketing cloud"
    },
    "/about-us": {
        keywords: "about wisecode, salesforce, salesforce partners, salesforce implementation partners, sfmc, marketing cloud, mulesoft, pardot",
        title: "About Us | Wise Code",
        description: "Wise code in a software development firm focused on offering solution for marketing using salesforce marketing cloud"
    },
    "/contact-us": {
        keywords: "contact wisecode, salesforce, salesforce partners, salesforce implementation partners, sfmc, marketing cloud, mulesoft, pardot",
        title: "Contact Us | Wise Code",
        description: "Wise code in a software development firm focused on offering solution for marketing using salesforce marketing cloud"
    }
}