import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu } from 'antd';
import { Link } from 'gatsby';
import logo from '../../images/logo-n-3.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar } from 'react-bootstrap';

const { Header } = Layout;

const items = [
  {
    value: "Home",
    key: "",
  },
  {
    value: "About Us",
    key: 'about-us',
  },
  {
    value: "Contact Us",
    key: 'contact-us',
  }
]

const PageHeader = (props: any) => {
  const [isTransparentHeader, setIsTransparentHeader] = useState(false);
  useEffect(() => {
    console.log(props)
    props.isTransparentHeader != "" ? setIsTransparentHeader(props.isTransparentHeader) : setIsTransparentHeader(false);
  })
  return (
  <>
    {false ? <Header style={{backgroundColor: "Red", color: "white"}}>Website Under Construction</Header> : <></>}
      <Navbar fixed="top" className="px-4" style={{backgroundColor: `${isTransparentHeader ? "#dffcff00" : "#dffcff"}`}} variant={isTransparentHeader ? "dark" : "light"} expand="lg">
          <Navbar.Brand>
            <Link to="/">
              <img width="150" src={logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
            <Nav>
              {items.map((item, index) => (
                  <Link className="py-2 mx-5 px-1" to={`/${item.key}`} style={{textDecoration: "none", color: `${isTransparentHeader ? "#dffcff" : "#2c494c"}`, borderBottomWidth: item.key==props.selectedKey ? "0.15em" : "0em", borderBottomColor: `${isTransparentHeader ? "#dffcff" : "#2c494c"}`, borderBottomStyle: "solid"}}>
                    {item.value}
                  </Link>
              ))}
            </Nav>
          </Navbar.Collapse>
      </Navbar>
  </>
)}

export default PageHeader